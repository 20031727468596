import api from '../api'
// import { merge } from 'lodash'
const state = () => ({
    studyGuide: {},
    studyGuides: [],
    studyGuidesMeta: []
});


const getters = {
    getStudyGuides: state =>  state.studyGuides,
    getStudyGuide: state =>  state.studyGuide,
    getStudyGuidesMeta: state =>  state.studyGuidesMeta,
}

const mutations = {
    
    setStudyGuideList(state, payload) {
        state.studyGuides = payload.map((item, index) => {
            item.sl = index + 1;
            return item;
        })
    },
    setStudyGuidesMeta(state, studyGuidesMeta) {
        state.studyGuidesMeta = studyGuidesMeta
    },
    setStudyGuideShow(state, payload) {
        state.studyGuide = payload;
    },

}

const actions = {
    async actSaveStudyGuide ( store, payload)  {
        return new Promise((resolve, reject) => {
            try {
                api('post', `api/v1/study-guides`, payload)
                    .then( response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actListStudyGuide ({ commit }, query = '')  {

        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/study-guides/list${query}`)
                    .then( response => {
                        commit('setStudyGuideList', response.items)
                        commit('setStudyGuidesMeta', response.meta);
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actShowStudyGuide ({ commit }, studyGuideId)  {
        return new Promise((resolve, reject) => {
            try {
                api('get', `api/v1/study-guides/${studyGuideId}`)
                    .then( response => {
                        commit('setStudyGuideShow', response.items)
                        resolve(response.items)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actDeleteStudyGuide ({ dispatch }, studyGuideId)  {
        return new Promise((resolve, reject) => {
            try {
                api('delete', `api/v1/study-guides/${studyGuideId}`)
                    .then( () => {
                        dispatch('actListStudyGuide', ``)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actChangeStatusStudyGuide (store, {studyGuideId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('patch', `api/v1/study-guides/change-status/${studyGuideId}`, payload)
                    .then( response => {
                        resolve(response.message)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },
    
    async actUpdateStudyGuide (store, {explanationId, payload})  {
        return new Promise((resolve, reject) => {
            try {
                api('put', `api/v1/study-guides/${explanationId}`, payload)
                    .then( response => {
                        resolve(response.message)
                    })
                    .catch(error => reject(error))
                } catch (error) {
                    reject(error);
                }
        })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }