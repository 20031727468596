<template>
  <div>
    <h2 v-if="label" 
    :class="[
        requiredLabel ? 'required' : '',
        italic ? 'text-italic' : '',
        opacityClass,
        labelClass
      ]">{{ label }}</h2>
    <ckeditor
      :editor="editor"
      v-model="editorData"
      :config="editorConfig={toolbar}"
    ></ckeditor>
    <b class="text-danger text-xs" v-if="message">{{ message }}</b>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  props: {
    label: {
      type: [String, Boolean],
      default: () => "Editor label goes to here",
    },
    message: {
      type: String,
      default: () => null
    },
    italic: {
      type: String,
    },
    opacityClass: {
      type: String,
    },
    requiredLabel: {
      type: Boolean,
      default: () => false
    },
    labelClass: {
      type: String,
      default: () => 'text-uppercase text-victoria mb-3'
    },
    toolbar: {
      type: Array,
      default: () => [ 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
    },
    value: {
      type: String
    }
  },
  data() {
    return {
      editor: ClassicEditor,
    };
  },
  computed: {
    editorData: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value);
        // this.$emit('input', value.replace(/&nbsp;/g, ' '));
        //console.log(value.replace(/&nbsp;/g, ' '))
      }
    }
  },
  methods: {
  }
};
</script>

<style lang="scss">

@import '@/assets/sass/abstracts/_variables.scss';

.ck-content { height:180px; }
 .ck.ck-toolbar > .ck-toolbar__items {
  justify-content: flex-end !important;
  overflow: hidden;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  background-color:$victoria-color;
}
.ck.ck-reset_all,
.ck.ck-reset_all * {
  color: #fff !important;
}
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background-color: #7a75adc2;
}

</style>
