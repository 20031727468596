<template>
    <div>
        <Alert background-color="#514b99" classObject="rounded-lg p-2">
            <div class="flex flex-wrap justify-between justify-sm-center justify-xs-center">
            <div class="mx-2 p-2">
                <div class="text-white text-sm-center text-xs-center">
                    <h3 class="opacity-25 pb-2 text-uppercase">{{ $t(label) }}</h3>
                    <div><span class="font-bold text-uppercase text-xl">{{ currentDay }}</span><small class="pl-2">{{ currentDate }}</small></div>
                </div>
            </div>
            <div class="mx-2 flex align-center">
                <md-button class="ml-0 outlined rounded text-white" @click="onSignOut">Sign Out</md-button>
                <md-button class=" bg-green rounded text-white" @click="onForceSignOut">Force Sign Out</md-button>
                <div class="outline-gray-600 p-3 rounded text-white"><span class="text-3xl font-semibold">00: 25:</span> <span>12</span></div>
            </div>
            </div>
        </Alert>
    </div>
</template>

<script>
import { Alert} from '@/components';
import { mapMutations } from "vuex";
export default {
    components : {
        Alert
    },
    props: {
        label: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            currentDate: new Date().toLocaleString('default', { day: 'numeric', month: "short", year: 'numeric'}),
            currentDay: new Date().toLocaleString('default', { weekday: 'short'}),
        }
    },
    methods: { 
        ...mapMutations({
            dialog: "setShowDialog",
        }),
        onSignOut() {
            this.$emit('on-signout', {
                src : '/images/worning.png',
                text : 'You can not signout before complete all the tasks',
                message : false,
                cancelButton : true,
            })
        }, 
        onForceSignOut() {
            this.$emit('on-signout', {
                src : '/images/attention.png',
                text : 'Are you sure, You want to sign out because',
                message : true,
                cancelButton : false,
            });
        }

    }

}
</script>