<template>
  <div>
    <UserAvatar class="mb-6" :avatar="avatar" />
    <MenuItems :items="items" />
  </div>
</template>

<script>
import UserAvatar from "@/components/molecule/user/UserAvatar";
import MenuItems from "@/components/molecule/menu/MenuItems";

export default {
  components: {
    UserAvatar,
    MenuItems,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    avatar: {
      type: Object
    }
  },
};
</script>

<style></style>
