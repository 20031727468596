<template>
  <div class="outline-gray-400 p-1 rounded center">
      <md-icon
        class="p-4 text-white m-0 rounded text-2xl"
        :class="[ (start <= 1) ? 'bg-periwinkle-gray': 'pointer', bgColor]"
        @click.native="onPrevious"
      >
        keyboard_arrow_left
      </md-icon>
        <span class="mx-4 text-base font-semibold">{{ start }} Of  {{ end }} </span>
      <md-icon @click.native="onNext" class="p-4 text-white m-0 rounded text-2xl"
      :class="[ (end <= start) ? 'bg-periwinkle-gray': 'pointer', bgColor]"
      >
        keyboard_arrow_right
      </md-icon>
  </div>
</template>

<script>
export default {
    props: {
      start: {
        type: Number,
        default: () => 1
      },
      end: {
        type: Number,
        default: () => 100
      },
      limit: {
        type: Number,
        default: () => 20
      },
      total: {
        type: Number
      },
      bgColor: {
        type: String,
        default: 'bg-victoria'
      }
    },
    methods: {
      onPrevious() {
        console.log("111111111");
        if(this.start > 1) {
           this.$emit('on-start', this.start-1);
        }
      },
      onNext() {
        if(this.end > this.start) {
          this.$emit('on-end', this.start+1);
        }
      }
    }
};
</script>

<style></style>
