const AWS = require('aws-sdk');

const spacesEndpoint = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: process.env.VUE_APP_DIGITALOCEAN_SPACES_KEY,
    secretAccessKey: process.env.VUE_APP_DIGITALOCEAN_SPACES_SECRET
});

export function uploadObjects(params) {

    return new Promise((resolve, reject) => {
        s3.putObject(params, function(err) {
            if (err){
                reject(err);//console.log(err, err.stack);
            }
            else {
                resolve(params);
            }
        });
    })
}

export async  function putObject(file,targetedPath, acl='public-read') {

    const path  = targetedPath.toLowerCase()+'/';
    var params = {
        Bucket: process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET,
        Key: process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'+ path +file.name,
        Body: file,
        ACL: acl,
    };
   uploadObjects(params).then(response => {
       console.log(response);
   });
}

export async function putObjects(files) {

    // files = [
    //     { file: 'image.jpg', targetPath: '', acl: 'public-read' },
    // ];
    files.map(data => {
        const path  = data.targetPath.toLowerCase()+'/';
        var params = {
            Bucket:  process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET,
            Key: process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'+ path + data.file.name,
            Body: data.file,
            ACL: data.acl,
        };
        uploadObjects(params).then(response => {
            console.log('check',response);
        });
    });
}

export function copyObject(CopySource, key, acl='public-read') {
    
    var copyObjectParams = {
        Bucket: process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET, 
        CopySource: '/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'+ CopySource, 
        Key: process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'+key,
        ACL: acl,
    };
    
    s3.copyObject(copyObjectParams, function(err, data) {           
        if (err) console.log(err, err.stack);
        else   console.log(data);
    });

}

export function deleteObjects(keys) {
    var objects = [];
    keys.forEach(function(key) {
        objects.push({Key: process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'+key})
    })
    var params = {
        Bucket: process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET,
        Delete: {
            Objects: objects,
            Quiet: false
        }
    };
    s3.deleteObjects(params, function(err, data) {
        if (err) console.log(err, err.stack);
        else     console.log(data);
    });
}

export function deleteObject(key) {
    
    var deleteObjectParams = {
        Bucket: process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET,
        Key: process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'+key
    };
    

    s3.deleteObject(deleteObjectParams, function(err, data) {
      if (err) console.log(err, err.stack);
      else     console.log(data);
    });

}

export function renameObject(oldKey, newkey, acl='public-read') {
    copyObject(oldKey,newkey, acl);
    deleteObject(oldKey);
}

export function renameFile(filePath, newName){
    var fileExt = filePath.split('.').pop();
    var fileName = filePath.split('/').pop();
    let path = filePath.replace(fileName, "");
    let newPath = path+newName+'.'+fileExt;
    return newPath.toLowerCase();
}
