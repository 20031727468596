export const teacher = [
  {
    path: '/',
    redirect: '/teacher/portal',
    name: 'root'
  },
  {
    path: '/teacher',
    name: 'Teacher',
    redirect: {name: 'teacher.portal'},
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'portal',
        name: 'teacher.portal',
        component: () => import(/* webpackChunkName: "teacher-portal" */ '../views/teacher/TeacherPortalView.vue'),
        meta: { layout: 'teacher' }
      },
      {
        path: 'task',
        name: 'teacher.task',
        component: () => import(/* webpackChunkName: "teacher-task" */ '../views/teacher/TeacherTaskView.vue'),
        meta: { layout: 'teacher' }
      },
      {
        path: 'schedule',
        name: 'teacher.schedule',
        component: () => import(/* webpackChunkName: "teacher-schedule" */ '../views/teacher/TeacherScheduleView.vue'),
        meta: { layout: 'teacher' }
      },
      {
        path: 'question-creation',
        component: {
          render(c) { return c('router-view') }
        },
        meta: { layout: 'teacher' },
        redirect: { name: 'teacher.questionCreation' },
        children: [
          {
            path: '',
            name: 'teacher.questionCreation',
            component: () => import(/* webpackChunkName: "teacher-question-creation" */ '../views/teacher/question-creation/IndexView.vue'),
            meta: { layout: 'teacher' },
          },
          {
            path: ':slug/pte-preview/:id',
            name: 'teacher.ptePracticePreview',
            component: () => import( '../views/student/pte-practice/ptePracticeSlide.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'pte-question',
            name: 'teacher.pteQuestion',
            component: () => import(/* webpackChunkName: "teacher-pte-question" */ '../views/teacher/question-creation/pte-question/IndexView.vue'),
            meta: { layout: 'teacher' },
            redirect: { name: 'pte-question.reading' },
            children: [
              {
                path: 'reading',
                name: 'pte-question.reading',
                component: () => import(/* webpackChunkName: "teacher-pte-question-reading" */ '../views/teacher/question-creation/pte-question/ReadingView.vue'),
                meta: { layout: 'teacher' }
              },
              {
                path: 'writing',
                name: 'pte-question.writing',
                component: () => import(/* webpackChunkName: "teacher-pte-question-writing" */ '../views/teacher/question-creation/pte-question/WritingView.vue'),
                meta: { layout: 'teacher' }
              },
              {
                path: 'speaking',
                name: 'pte-question.speaking',
                component: () => import(/* webpackChunkName: "teacher-pte-question-speaking" */ '../views/teacher/question-creation/pte-question/SpeakingView.vue'),
                meta: { layout: 'teacher' }
              },
              {
                path: 'listening',
                name: 'pte-question.listening',
                component: () => import(/* webpackChunkName: "teacher-pte-question-listening" */ '../views/teacher/question-creation/pte-question/ListeningView.vue'),
                meta: { layout: 'teacher' }
              }
            ]
          },
          {
            path: 'pte-question/create',
            name: 'pte-question.create',
            component: () => import(/* webpackChunkName: "teacher-pte-question-create" */ '../views/teacher/question-creation/pte-question/CreateView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'pte-question/edit/:id',
            name: 'pte-question.edit',
            component: () => import(/* webpackChunkName: "teacher-pte-question-edit" */ '../views/teacher/question-creation/pte-question/EditView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'pte-quiz',
            name: 'teacher.pteQuiz',
            component: () => import(/* webpackChunkName: "teacher-pte-quiz" */ '../views/teacher/question-creation/pte-quiz/QuizList.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'pte-quiz/create',
            name: 'pte-quiz.create',
            component: () => import(/* webpackChunkName: "teacher-pte-quiz" */ '../views/teacher/question-creation/pte-quiz/CreateQuiz.vue'),
            meta: { layout: 'teacher' }
          }
        ]
      },
      {
        path: 'test-creation',
        component: {
          render(c) { return c('router-view') }
        },
        redirect: { name: 'testCreation' }, // Need to navigate for mock-exam and practice-test
        children: [
          {
            path: '',
            name: 'testCreation',
            component: () => import(/* webpackChunkName: "teacher-test-creation" */ '../views/teacher/test-creation/IndexView.vue'),
            meta: {layout: 'teacher'}
          },
          {
            path: 'list',
            name: 'testCreation.list',
            component: () => import(/* webpackChunkName: "teacher-test-creation" */ '../views/student/pte-practice/PtePracticeCollapseView.vue'),
            meta: {layout: 'teacher'}
          },
          {
            path: 'quiz-list',
            name: 'quizCreation.list',
            component: () => import(/* webpackChunkName: "teacher-test-creation" */ '../views/student/quiz-test/QuizTestCollapseView.vue'),
            meta: {layout: 'teacher'}
          },
          {
            path: 'create',
            name: 'testCreation.create',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/create/IndexView.vue'),
            meta: {layout: 'teacher'},
            redirect: { name: 'testCreation.testInformation' },
            children: [
              {
                path: 'test-information',
                name: 'testCreation.testInformation',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/create/TestInformationView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'time-set',
                name: 'testCreation.timeSet',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/create/TimeSetView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'create-type',
                name: 'testCreation.createType',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/create/CreateTypeView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'number-of-question',
                name: 'testCreation.numberOfQuestion',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/create/NumberOfQuestionView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'choose-question',
                name: 'testCreation.chooseQuestion',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/create/ChooseQuestionView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'review',
                name: 'testCreation.review',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/create/ReviewView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'test-preview',
                name: 'testCreation.testPreview',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/create/TestPreviewView.vue'),
                meta: {layout: 'teacher'},
              },
              
            ]
          },
          {
            path: 'create',
            name: 'quizCreation.create',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/quiz-test/create/IndexView.vue'),
            meta: {layout: 'teacher'},
            redirect: { name: 'quizCreation.testInformation' },
            children: [
              {
                path: 'test-information',
                name: 'quizCreation.testInformation',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/quiz-test/create/TestInformationView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'time-set',
                name: 'quizCreation.timeSet',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/quiz-test/create/TimeSetView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'create-type',
                name: 'quizCreation.createType',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/quiz-test/create/CreateTypeView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'number-of-question',
                name: 'quizCreation.numberOfQuestion',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/quiz-test/create/NumberOfQuestionView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'choose-question',
                name: 'quizCreation.chooseQuestion',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/quiz-test/create/ChooseQuestionView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'review',
                name: 'quizCreation.review',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/quiz-test/create/ReviewView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'quiz-set-preview',
                name: 'quizCreation.testPreview',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/test-creation/quiz-test/create/TestPreviewView.vue'),
                meta: {layout: 'teacher'},
              },
              
            ]
          },
          {
            path: 'practice-test',
            component: {
              render(c) { return c('router-view') }
            },
            children: [
              {
                path: '',
                name: 'practiceTest',
                component: () => import(/* webpackChunkName: "teacher-test-creation-index" */ '../views/teacher/test-creation/practice-test/IndexView.vue'),
                meta: { layout: 'teacher' },
              },
              {
                path: 'all',
                name: 'dataList.all',
                component: () => import(/* webpackChunkName: "teacher-test-creation-data-all" */ '../views/teacher/test-creation/practice-test/data-list/AllView.vue'),
                meta: { layout: 'teacher' }
              },
              {
                path: 'reading',
                name: 'dataList.reading',
                component: () => import(/* webpackChunkName: "teacher-test-creation-data-reading" */ '../views/teacher/test-creation/practice-test/data-list/ReadingView.vue'),
                meta: { layout: 'teacher' }
              },
              {
                path: 'writing',
                name: 'dataList.writing',
                component: () => import(/* webpackChunkName: "teacher-test-creation-data-writing" */ '../views/teacher/test-creation/practice-test/data-list/WritingView.vue'),
                meta: { layout: 'teacher' }
              },
              {
                path: 'speaking',
                name: 'dataList.speaking',
                component: () => import(/* webpackChunkName: "teacher-test-creation-data-speaking" */ '../views/teacher/test-creation/practice-test/data-list/SpeakingView.vue'),
                meta: { layout: 'teacher' }
              },
              {
                path: 'listening',
                name: 'dataList.listening',
                component: () => import(/* webpackChunkName: "teacher-test-creation-data-listening" */ '../views/teacher/test-creation/practice-test/data-list/ListeningView.vue'),
                meta: { layout: 'teacher' }
              },
              {
                path: 'combo',
                name: 'dataList.combo',
                component: () => import(/* webpackChunkName: "teacher-test-creation-data-combo" */ '../views/teacher/test-creation/practice-test/data-list/ComboView.vue'),
                meta: { layout: 'teacher' }
              },
              {
                path: 'create',
                name: 'dataList.create',
                component: () => import(/* webpackChunkName: "teacher-test-creation-data-combo" */ '../views/teacher/test-creation/practice-test/CreateView.vue'),
                meta: { layout: 'teacher' }
              },
              
              {
                path: 'show/:id',
                name: 'dataList.show',
                component: () => import(/* webpackChunkName: "teacher-test-creation-data-combo" */ '../views/teacher/test-creation/practice-test/ShowView.vue'),
                meta: { layout: 'teacher' }
              },

            ]
          },
          {
            path: 'mock-exam',
            component: {
              render(c) { return c('router-view') }
            },
            children: [
              {
                path: '',
                name: 'mockExam',
                component: () => import(/* webpackChunkName: "teacher-test-creation-index" */ '../views/teacher/test-creation/mock-exam/IndexView.vue'),
                meta: { layout: 'teacher' },
              },
              {
                path: 'full-mock',
                name: 'mockExam.FullMock',
                component: () => import(/* webpackChunkName: "teacher-test-creation-index" */ '../views/teacher/test-creation/mock-exam/data-list/FullMockListView.vue'),
                meta: { layout: 'teacher' },
              }
            ]
          },
        ]
      },
      {
        path: 'material',
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            name: 'material.nav',
            component: () => import(/* webpackChunkName: "teacher-material-nav" */ '../views/teacher/course-material/IndexView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'dashboard',
            name: 'material.dashboard',
            redirect: {name: 'material.dashboard.fromPlatform.all'},
            component: () => import(/* webpackChunkName: "teacher-material-dashboard" */ '../views/teacher/course-material/DashboardView.vue'),
            meta: { layout: 'teacher' },
            children: [
              {
                path: 'from-platform',
                name: 'material.dashboard.fromPlatform',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/FromPlatform.vue'),
                meta: { layout: 'teacher' },
                redirect: {name: 'material.dashboard.fromPlatform.all'},
                children: [
                  {
                    path: 'all',
                    name: 'material.dashboard.fromPlatform.all',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                  {
                    path: 'pdf-file',
                    name: 'material.dashboard.fromPlatform.pdfFile',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                  {
                    path: 'word-file',
                    name: 'material.dashboard.fromPlatform.wordFile',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                  {
                    path: 'image-file',
                    name: 'material.dashboard.fromPlatform.imageFile',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                  {
                    path: 'audio-file',
                    name: 'material.dashboard.fromPlatform.audioFile',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                  {
                    path: 'video-file',
                    name: 'material.dashboard.fromPlatform.videoFile',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                ]
              },
              {
                path: 'from-student',
                name: 'material.dashboard.fromStudent',
                component: () => import(/* webpackChunkName: "teacher-material-dashboard-grid" */ '../views/teacher/course-material/dashboard/FromStudent.vue'),
                meta: { layout: 'teacher' },
                redirect: {name: 'material.dashboard.fromStudent.all'},
                children: [
                  {
                    path: 'all',
                    name: 'material.dashboard.fromStudent.all',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                  {
                    path: 'pdf-file',
                    name: 'material.dashboard.fromStudent.pdfFile',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                  {
                    path: 'word-file',
                    name: 'material.dashboard.fromStudent.wordFile',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                  {
                    path: 'image-file',
                    name: 'material.dashboard.fromStudent.imageFile',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                  {
                    path: 'audio-file',
                    name: 'material.dashboard.fromStudent.audioFile',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  },
                  {
                    path: 'video-file',
                    name: 'material.dashboard.fromStudent.videoFile',
                    component: () => import(/* webpackChunkName: "teacher-material-dashboard-list" */ '../views/teacher/course-material/dashboard/ListView.vue'),
                    meta: { layout: 'teacher' },
                  }
                ]
              }
            ]
          },
          {
            path: 'group',
            name: 'material.group',
            component: () => import(/* webpackChunkName: "teacher-material-group" */ '../views/teacher/course-material/GroupView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'course-wise',
            name: 'material.courseWise',
            component: () => import(/* webpackChunkName: "teacher-material-course-wise" */ '../views/teacher/course-material/CourseWiseView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'special',
            name: 'material.special',
            component: () => import(/* webpackChunkName: "teacher-material-special" */ '../views/teacher/course-material/SpecialView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'word-list',
            name: 'material.wordList',
            component: () => import(/* webpackChunkName: "teacher-material-word-list" */ '../views/teacher/course-material/WordListView.vue'),
            meta: { layout: 'teacher' }
          },
        ]
      },
      {
        path: 'pending-evaluation',
        name: 'pendingEvaluation',
        component: () => import(/* webpackChunkName: "teacher-material-evaluation" */ '../views/teacher/pending-evaluation/IndexView.vue'),
        meta: { layout: 'teacher' },
        redirect: {name: 'pendingEvaluation.manual'},
        children: [
          {
            path: 'manual',
            name: 'pendingEvaluation.manual',
            component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/manual/IndexView.vue'),
            meta: {layout: 'teacher'},
            redirect: { name: 'pendingEvaluation.manual.practiceTest' },
            children: [
              {
                path: 'practice-test',
                name: 'pendingEvaluation.manual.practiceTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/manual/PracticeTestView.vue'),
                meta: {layout: 'teacher'},
              },
              
              {
                path: 'practice-test/evaluation',
                name: 'pendingEvaluation.manual.practiceTest.evaluation',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/manual/PracticeTestEvaluationView.vue'),
                meta: {layout: 'teacher'},
              },

              {
                path: 'full-mock-test',
                name: 'pendingEvaluation.manual.fullMockTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/manual/ManualView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'partial-mock-test',
                name: 'pendingEvaluation.manual.partialMockTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/manual/ManualView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'diagnostic-test',
                name: 'pendingEvaluation.manual.diagnosticTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/manual/ManualView.vue'),
                meta: {layout: 'teacher'},
              }
            ]
          },
          {
            path: 'automatic',
            name: 'pendingEvaluation.automatic',
            component: () => import(/* webpackChunkName: "teacher-pending-evaluation-automatic" */ '../views/teacher/pending-evaluation/automatic/IndexView.vue'),
            meta: {layout: 'teacher'},
            redirect: { name: 'pendingEvaluation.automatic.practiceTest' },
            children: [
              {
                path: 'practice-test',
                name: 'pendingEvaluation.automatic.practiceTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/automatic/AutomaticView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'full-mock-test',
                name: 'pendingEvaluation.automatic.fullMockTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/automatic/AutomaticView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'partial-mock-test',
                name: 'pendingEvaluation.automatic.partialMockTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/automatic/AutomaticView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'diagnostic-test',
                name: 'pendingEvaluation.automatic.diagnosticTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/automatic/AutomaticView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'quiz-test',
                name: 'pendingEvaluation.automatic.quizTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/automatic/AutomaticView.vue'),
                meta: {layout: 'teacher'},
              },

            ]
          },
          {
            path: 'feedback',
            name: 'pendingEvaluation.feedback',
            component: () => import(/* webpackChunkName: "teacher-pending-evaluation-automatic" */ '../views/teacher/pending-evaluation/feedback/IndexView.vue'),
            meta: {layout: 'teacher'},
            redirect: { name: 'pendingEvaluation.feedback.practiceTest' },
            children: [
              {
                path: 'practice-test',
                name: 'pendingEvaluation.feedback.practiceTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/feedback/FeedbackView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'full-mock-test',
                name: 'pendingEvaluation.feedback.fullMockTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/feedback/FeedbackView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'partial-mock-test',
                name: 'pendingEvaluation.feedback.partialMockTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/feedback/FeedbackView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'diagnostic-test',
                name: 'pendingEvaluation.feedback.diagnosticTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/feedback/FeedbackView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'quiz-test',
                name: 'pendingEvaluation.feedback.quizTest',
                component: () => import(/* webpackChunkName: "teacher-pending-evaluation-manual" */ '../views/teacher/pending-evaluation/feedback/FeedbackView.vue'),
                meta: {layout: 'teacher'},
              }
            ]
          }
        ]
      },
      {
        path: 'study-plan',
        name: 'studyPlan',
        component: () => import(/* webpackChunkName: "teacher-study-plan" */ '../views/teacher/study-plan/IndexView.vue'),
        meta: { layout: 'teacher' },
        redirect: { name: 'studyPlan.achieves' },
        children: [
          {
            path: 'achieves',
            name: 'studyPlan.achieves',
            component: () => import(/* webpackChunkName: "teacher-study-plan-achieves" */  '../views/teacher/study-plan/AchievesView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'list',
            name: 'studyPlan.list',
            component: () => import(/* webpackChunkName: "teacher-study-plan-list" */ '../views/teacher/study-plan/PlanListView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'calendar',
            name: 'studyPlan.calendar',
            component: () => import(/* webpackChunkName: "teacher-study-plan-calendar" */ '../views/teacher/study-plan/PlanCalendarView.vue'),
            meta: { layout: 'teacher' }
          }

        ]
      },
      {
        path: 'live-class',
        name: 'teacher.liveClass',
        component: () => import(/* webpackChunkName: "teacher-live-class" */ '../views/common/live-class/IndexView.vue'),
        meta: { layout: 'teacher' },
        redirect: {name: 'teacher.liveClass.nowPlaying'},
        children: [
          {
            path: 'now-playing',
            name: 'teacher.liveClass.nowPlaying',
            component: () => import(/* webpackChunkName: "teacher-live-class-now-paying" */ '../views/teacher/live-class/NowPlayingView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'next-live',
            name: 'teacher.liveClass.nextLive',
            component: () => import(/* webpackChunkName: "teacher-live-class-next-live" */ '../views/common/live-class/NextLiveView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'schedule',
            name: 'teacher.liveClass.schedule',
            component: () => import(/* webpackChunkName: "teacher-live-class-schedule-view" */ '../views/common/live-class/ScheduleView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'schedule/list',
            name: 'teacher.liveClass.scheduleList',
            component: () => import(/* webpackChunkName: "teacher-live-class-schedule-list" */ '../views/common/live-class/ScheduleListView.vue'),
            meta: { layout: 'teacher' }
          },

        ]
      },
      {
        path: 'crm',
        name: 'crm',
        component: () => import(/* webpackChunkName: "teacher-crm" */ '../views/teacher/crm/IndexView.vue'),
        meta: { layout: 'teacher' },
        redirect: { name: 'crm.awardPoint' },
        children: [
          {
            path: 'award-point',
            name: 'crm.awardPoint',
            component: () => import(/* webpackChunkName: "teacher-award-point" */ '../views/teacher/crm/AwardPointView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'performance-checking',
            name: 'crm.performanceChecking',
            component: () => import(/* webpackChunkName: "teacher-award-point" */ '../views/teacher/crm/PerformanceCheckingView.vue'),
            meta: { layout: 'teacher' }
          },

        ]
      },
      {
        path: 'online-class',
        name: 'onlineClass',
        component: () => import(/* webpackChunkName: "teacher-online-class" */ '../views/teacher/online-class/IndexView.vue'),
        meta: { layout: 'teacher' },
        redirect: { name: 'onlineClass.online-class-list' },
        children: [
          {
              path: 'online-class-list',
              name: 'onlineClass.online-class-list',
              component: () => import(/* webpackChunkName: "teacher-online-class" */ '../views/teacher/online-class/TeacherOnlineClassView.vue'),
              meta: { layout: 'teacher' },
              children: [
                {
                  path: 'class-details',
                  name: 'onlineClass.online-class-list.details',
                  component: () => import(/* webpackChunkName: "teacher-online-class" */ '../components/molecule/online-class/ShowModuleList.vue'),
                  meta: { layout: 'teacher' },
                  children: [
                    {
                      path: 'modules',
                      name: 'onlineClass.online-class-list.details-modules',
                      component: () => import(/* webpackChunkName: "teacher-online-class" */ '../views/teacher/online-class/details/Modules.vue'),
                      meta: { layout: 'teacher' }
                    },
                    {
                      path: 'class-description',
                      name: 'onlineClass.online-class-list.details-class-description',
                      component: () => import(/* webpackChunkName: "teacher-online-class" */ '../views/teacher/online-class/details/ClassDescription.vue'),
                      meta: { layout: 'teacher' }
                    },
                    {
                      path: 'why-importants',
                      name: 'onlineClass.online-class-list.details-importants',
                      component: () => import(/* webpackChunkName: "teacher-online-class" */ '../views/teacher/online-class/details/WhyImportant.vue'),
                      meta: { layout: 'teacher' }
                    },
                  ]
                },
                {
                  path: 'chapter',
                  name: 'onlineClass.online-class-list.chapter',
                  component: () => import(/* webpackChunkName: "teacher-online-class" */ '../components/molecule/online-class/ShowChapterDetails.vue'),
                  meta: { layout: 'teacher' },
                  children: [
                    {
                      path: 'details',
                      name: 'onlineClass.online-class-list.chapter-details',
                      component: () => import(/* webpackChunkName: "teacher-online-class" */ '../views/teacher/online-class/details/ChapterDetails.vue'),
                      meta: { layout: 'teacher' }
                    },
                  ]
                },
              ]
          },
        ]
      },
      {
        path: 'online-class-create',
        name: 'onlineClassCreation',
        component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/IndexView.vue'),
        meta: {layout: 'teacher'},
        redirect: { name: 'onlineClassCreation.classInformation' },
        children: [
          {
            path: 'class-information',
            name: 'onlineClassCreation.classInformation',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/ClassInformationView.vue'),
            meta: {layout: 'teacher'},
          },
          {
            path: 'why-important',
            name: 'onlineClassCreation.whyImportant',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/WhyImportant.vue'),
            meta: {layout: 'teacher'},
          },
          {
            path: 'faq',
            name: 'onlineClassCreation.faq',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/faq/IndexView.vue'),
            meta: {layout: 'teacher'},
            redirect: { name: 'onlineClassCreation.faq.newFaq' },
            children: [
              {
                path: 'new-faq',
                name: 'onlineClassCreation.faq.newFaq',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/faq/NewFaq.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'import-faq',
                name: 'onlineClassCreation.faq.importFaq',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/faq/ImportFaqFromList.vue'),
                meta: {layout: 'teacher'},
              },
            ]
          },
          {
            path: 'class-mapping',
            name: 'onlineClassCreation.classMapping',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/ClassMapping.vue'),
            meta: {layout: 'teacher'},
          },
          {
            path: 'module-information',
            name: 'onlineClassCreation.moduleInformation',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/module-info/IndexView.vue'),
            meta: {layout: 'teacher'},
            redirect: { name: 'onlineClassCreation.moduleInformation.default' },

            children: [
              {
                path: 'module-information/:id',
                name: 'onlineClassCreation.moduleInformation.view',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/module-info/ModulesInformationView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'create',
                name: 'onlineClassCreation.moduleInformation.default',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/module-info/ModulesInformationView.vue'),
                meta: {layout: 'teacher'},
              },
            ]
          },
          {
            path: 'chapter-details',
            name: 'onlineClassCreation.chapterDetails',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/ChaptersDetails.vue'),
            meta: {layout: 'teacher'},
          },
          {
            path: 'rules',
            name: 'onlineClassCreation.rules',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/Rules.vue'),
            meta: {layout: 'teacher'},
            children: [
              {
                path: 'module/:id',
                name: 'onlineClassCreation.rules.module',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/Rules.vue'),
                meta: {layout: 'teacher'},
              }
            ]
          },
          {
            path: 'review',
            name: 'onlineClassCreation.review',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/Review.vue'),
            meta: {layout: 'teacher'},
            children: [
              {
                path: 'module/:id',
                name: 'onlineClassCreation.review.module',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/Review.vue'),
                meta: {layout: 'teacher'},
              }
            ]
          },
          {
            path: 'preview',
            name: 'onlineClassCreation.preview',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/preview/Previews.vue'),
            meta: {layout: 'teacher'},
            children: [
              {
                path: 'module/:id',
                name: 'onlineClassCreation.preview.module',
                component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/preview/ChapterView.vue'),
                meta: {layout: 'teacher'},
                children: [
                  {
                    path: 'chapter/:chapterId',
                    name: 'onlineClassCreation.preview.module.chapter',
                    component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/preview/ChapterView.vue'),
                    meta: {layout: 'teacher'},
                  },
                ]
              },
            ]
          },
          {
            path: 'success',
            name: 'onlineClassCreation.success',
            component: () => import(/* webpackChunkName: "teacher-test-create" */ '../views/teacher/online-class/create/SuccesView.vue'),
            meta: {layout: 'teacher'},
          },
        ]
      },
      {
        path: 'blog',
        name: 'blog',
        component: () => import(/* webpackChunkName: "teacher-blog" */ '../views/teacher/blog/IndexView.vue'),
        meta: { layout: 'teacher' }
      },
      {
        path: 'communication',
        name: 'communication',
        component: () => import(/* webpackChunkName: "teacher-communication" */ '../views/teacher/communication/IndexView.vue'),
        meta: { layout: 'teacher' },
        redirect: { name: 'interaction' },
        children: [
          {
            path: 'interaction',
            name: 'interaction',
            component: () => import(/* webpackChunkName: "teacher-communication-interaction" */ '../views/teacher/communication/interaction/IndexView.vue'),
            meta: {layout: 'teacher'},
            redirect: { name: 'createList' },
            children: [
              {
                path: 'create-list',
                name: 'createList',
                component: () => import(/* webpackChunkName: "teacher-communication-create-list" */ '../views/teacher/communication/interaction/CreateListView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'import-export',
                name: 'importExport',
                component: () => import(/* webpackChunkName: "teacher-communication-import-export" */ '../views/teacher/communication/interaction/ImportExportView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'campaign',
                name: 'campaign',
                component: () => import(/* webpackChunkName: "teacher-communication-campaign" */ '../views/teacher/communication/interaction/CampaignView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'automation',
                name: 'automation',
                component: () => import(/* webpackChunkName: "teacher-communication-automation" */ '../views/teacher/communication/interaction/AutomationView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'form',
                name: 'interactionForm',
                component: () => import(/* webpackChunkName: "teacher-communication-form" */ '../views/teacher/communication/interaction/FormView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'deal',
                name: 'deal',
                component: () => import(/* webpackChunkName: "teacher-communication-deal" */ '../views/teacher/communication/interaction/DealView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'report',
                name: 'report',
                component: () => import(/* webpackChunkName: "teacher-communication-import" */ '../views/teacher/communication/interaction/ReportView.vue'),
                meta: {layout: 'teacher'},
              },
              
            ]
          },
          {
            path: 'popup-creation',
            name: 'popupCreation',
            component: () => import(/* webpackChunkName: "teacher-communication-popup-creation" */ '../views/teacher/communication/popup-creation/IndexView.vue'),
            meta: {layout: 'teacher'},
            redirect: { name: 'notice' },
            children: [
              {
                path: 'notice',
                name: 'notice',
                component: () => import(/* webpackChunkName: "teacher-communication-notice" */ '../views/teacher/communication/popup-creation/NoticeView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'forum',
                name: 'forum',
                component: () => import(/* webpackChunkName: "teacher-communication-forum" */ '../views/teacher/communication/popup-creation/ForumView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'suggestion',
                name: 'suggestion',
                component: () => import(/* webpackChunkName: "teacher-communication-suggestion" */ '../views/teacher/communication/popup-creation/SuggestionView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'feedback',
                name: 'feedback',
                component: () => import(/* webpackChunkName: "teacher-communication-feedback" */ '../views/teacher/communication/popup-creation/FeedbackView.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'blog-share',
                name: 'blogShare',
                component: () => import(/* webpackChunkName: "teacher-communication-blog-share" */ '../views/teacher/communication/popup-creation/BlogShareView.vue'),
                meta: {layout: 'teacher'},
              }
            ]
          },
          {
            path: 'leads',
            name: 'leads',
            component: () => import(/* webpackChunkName: "teacher-communication-leads" */ '../views/teacher/communication/leads/IndexView.vue'),
            meta: {layout: 'teacher'},
            redirect: { name: 'onlineAndBlog' },
            children: [
              {
                path: 'online-and-blog',
                name: 'onlineAndBlog',
                component: () => import(/* webpackChunkName: "teacher-communication-online-and-blog" */ '../views/teacher/communication/leads/OnlineAndBlogView.vue'),
                meta: {layout: 'teacher'},
              },
              
              {
                path: 'trial-class-student',
                name: 'trialClassStudent',
                component: () => import(/* webpackChunkName: "teacher-communication-trial-class-student" */ '../views/teacher/communication/leads/TrialClassStudent.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'expired-students',
                name: 'expiredStudents',
                component: () => import(/* webpackChunkName: "teacher-communication-expired-students" */ '../views/teacher/communication/leads/ExpiredStudents.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'seminars',
                name: 'seminars',
                component: () => import(/* webpackChunkName: "teacher-communication-seminars" */ '../views/teacher/communication/leads/Seminars.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'webinars',
                name: 'webinars',
                component: () => import(/* webpackChunkName: "teacher-communication-webinars" */ '../views/teacher/communication/leads/Webinars.vue'),
                meta: {layout: 'teacher'},
              },
              {
                path: 'workshop',
                name: 'workshop',
                component: () => import(/* webpackChunkName: "teacher-communication-workshop" */ '../views/teacher/communication/leads/Workshop.vue'),
                meta: {layout: 'teacher'},
              },

            ]
          }

        ]
      },
      {
        path: 'form',
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            name: 'form',
            component: () => import(/* webpackChunkName: "teacher-form" */ '../views/teacher/form/IndexView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'create',
            name: 'form.create',
            component: () => import(/* webpackChunkName: "teacher-form-create" */ '../views/teacher/form/CreateView.vue'),
            meta: { layout: 'teacher' }
          },
          {
            path: 'edit/:id',
            name: 'form.edit',
            component: () => import(/* webpackChunkName: "teacher-form-edit" */ '../views/teacher/form/EditView.vue'),
            meta: { layout: 'teacher' }
          },

        ],
      },
      {
        path: 'how-to-use-this-portal',
        name: 'teacherHowToUseThisPortal',
        component: () => import(/* webpackChunkName: "teacher-how-to-use" */ '../views/common/HowToUseThisPortalView.vue'),
        meta: { layout: 'teacher' }
      },
      {
        path: 'faq',
        name: 'teacherFaq',
        component: () => import(/* webpackChunkName: "teacher-faq" */ '../views/common/FAQView.vue'),
        meta: { layout: 'teacher' }
      },
      {
        path: 'blog',
        name: 'teacherBlog',
        component: () => import(/* webpackChunkName: "teacher-blog" */ '../views/common/BlogView.vue'),
        meta: { layout: 'teacher' }
      },
      {
        path: 'material',
        name: 'teacherMaterial',
        component: () => import(/* webpackChunkName: "teacher-page-material" */ '../views/common/MaterialView.vue'),
        meta: { layout: 'teacher' }
      },
      {
        path: 'registration-process',
        name: 'teacherRegistrationProcess',
        component: () => import(/* webpackChunkName: "teacher-registration-process" */ '../views/common/RegistrationProcessView.vue'),
        meta: { layout: 'teacher' }
      }
    ]
  }
]