<template>
  <div>
    <md-table v-model="localItems" @md-selected="onSelect" md-sort="name" @md-sorted="onSort" md-sort-order="asc">
      <md-table-empty-state
        :md-label="title"
      >
      </md-table-empty-state>
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="single"
        :class="{ 'on-hover': hoverAction }"
      >
        <slot name="row" :item="item"></slot>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { orderBy } from 'lodash';
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: () => 'Records'
    },
    hoverAction: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      data: null,
      localItems: []
    }
  },
  computed: {
    reactiveItems() {
      return this.items
    },
    title() {
      return `${this.label} not found`
    }
  },
  watch: {
    reactiveItems(value) {
      this.localItems = value;
    }
  },
  methods: {
    onSelect(item) {
      this.$emit("onSelect", item);
    },
    onSort (sort) {
     orderBy(this.localItems, [item => item[sort.name]], sort.type)
    },
  },
  mounted() {
    this.localItems = this.items;
  }
};
</script>

<style lang="scss">
.on-hover {
  position: relative;
  .action {
    position:absolute;
    right: 0px;
    //top: -4px;
    margin: 16px;
    visibility: hidden;
  }
}
.on-hover:hover .action {
  visibility: visible;
}
</style>
