import api from '../../api';
// import router from '@/router'
export const actLogin = ({commit}, payload) => {
    return new Promise((resolve, reject) => {
        try {
            api('post', 'api/v1/login', payload, 'public')
                .then(response => {
                    commit('setUser', response);
                    resolve(response);
                })
                .catch(error => reject(error))
        } catch (error) {
            reject(error);
        }
    })
}


export const actValidSubdomain = ({commit}, payload) => {
    return new Promise((resolve, reject) => {
        try {
            api('post', 'api/v1/valid-subdomain', payload, 'public')
                .then(response => {
                
                    commit('setUser', response);
                    resolve(response);
                })
                .catch(error => reject(error))
        } catch (error) {
            reject(error);
        }
    })
}

export const actGetUserInformation = ({ commit } , userId) => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/creator-info/${userId}`)
                .then(({items}) => {
                    commit('setCreatorInformation', items)
                })
                .catch(error => reject(error))
        } catch (error) {
            reject(error);
        }
    })
}

export const actGetAuthUser = ({ commit }) => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/auth-user`)
                .then((items) => {
                    
                    commit('setUser', items)
                })
                .catch(error => reject(error))
        } catch (error) {
            reject(error);
        }
    })
}


export const actUserRegister = ( store, payload)  => {
    return new Promise((resolve, reject) => {
        try {
            
            api('post', `api/v1/register`, payload)
                .then( response => {
                    
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actGetUserList = ({ commit } , query = '') => {
    return new Promise((resolve, reject) => {
        try {
           
            api('get', `api/v1/users${query}`)
                .then( ({ items, meta }) => {
                    
                    commit('setUserList', items);
                    commit('setUserMeta', meta);
                    commit('setSelectedUserList', items[0]);
                    resolve(items);
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}

export const actGetUserByUserId = (store, userId = '') => {
    return new Promise((resolve, reject) => {
        try {
            api('get', `api/v1/users/${userId}`)
                .then( (response) => {
                    resolve(response)
                })
                .catch(error => reject(error))
            } catch (error) {
                reject(error);
            }
    })
}