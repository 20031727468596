import { questionAndTestManagement } from './questionAndTestManagement';
import { branchManagement } from './branchManagement';
let userType = localStorage.getItem('user_type');
const layout = userType?userType.toLowerCase():'master';

export const master = [
    {
      path: '/master',
      name: 'master',
      component: {
        render(c) { return c('router-view') }
      },
      redirect: {name: 'master.portal'},
      children: [
        {
          path: 'portal',
          name: 'master.portal',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/MasterPortalView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'coaching-center',
          name: 'master.coachingCenter',
          component: () => import(/* webpackChunkName: "Coaching-Center" */ '../../views/coaching-center/IndexView.vue'),
          meta: { layout: layout },
        },         
        ...questionAndTestManagement,
        ...branchManagement,

        {
          path: 'people-management',
          name: 'master.peopleManagement',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/people-management/IndexView.vue'),
          meta: { layout: layout },
          children: [
            {
              path: '/people-management/job-list',
              name: 'master.peopleManagement.jobList',
              component: () => import('../../views/master/people-management/JobList.vue'),
              meta: { layout: layout},
            },
            {
              path: '/people-management/applicant-list',
              name: 'master.peopleManagement.applicantList',
              component: () => import('../../views/master/people-management/Applicant-List.vue'),
              meta: { layout: layout},
              children :[
                {
                  path: '/applicant-list/information',
                  name: 'master.peopleManagement.applicantList.information',
                  // component: () => import('../../components/molecule/job/ApplicantInformation.vue'),
                  // meta: { layout: layout},
          
                },
                {
                  path: '/applicant-list/score',
                  name: 'master.peopleManagement.applicantList.score',
                  // component: () => import('../../components/molecule/job/ApplicantScore.vue'),
                  // meta: { layout: layout},
          
                }
              ]
            },
            
           

          ]
        },
        {
          path: 'assessment-management',
          name: 'master.assessmentManagement',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/AssessmentManagement.vue'),
          meta: { layout: layout }
        },
        {
          path: 'rostering-and-schedule',
          name: 'master.rosteringAndSchedule',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/RosteringAndSchedule.vue'),
          meta: { layout: layout }
        },
        {
          path: 'communication-management',
          name: 'master.communicationManagement',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/CommunicationManagement.vue'),
          meta: { layout: layout }
        },
        {
          path: 'task-and-checklist-creation',
          name: 'master.taskAndChecklistCreation',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/TaskAndChecklistCreation.vue'),
          meta: { layout: layout }
        },
        {
          path: 'crm',
          name: 'master.crm',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/crm/IndexView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'live-class',
          name: 'master.liveClass',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/live-class/IndexView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'forms',
          name: 'master.form',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/form/IndexView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'study-plan',
          name: 'master.studyPlan',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/study-plan/IndexView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'dummy-coching',
          name: 'master.dummyCoching',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/dummy-coching-center/IndexView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'dummy-coching-create',
          name: 'master.dummyCochingCreate',
          component: () => import(/* webpackChunkName: "master-portal" */ '../../views/master/dummy-coching-center/CreateView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'how-to-use-this-portal',
          name: 'masterHowToUseThisPortal',
          component: () => import(/* webpackChunkName: "master-hot-to-use" */ '../../views/common/HowToUseThisPortalView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'faq',
          name: 'masterFaq',
          component: () => import(/* webpackChunkName: "master-hot-to-use" */ '../../views/common/FAQView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'blog',
          name: 'masterBlog',
          component: () => import(/* webpackChunkName: "master-hot-to-use" */ '../../views/common/BlogView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'material',
          name: 'masterMaterial',
          component: () => import(/* webpackChunkName: "master-hot-to-use" */ '../../views/common/MaterialView.vue'),
          meta: { layout: layout }
        },
        {
          path: 'registration-process',
          name: 'masterRegistrationProcess',
          component: () => import(/* webpackChunkName: "master-hot-to-use" */ '../../views/common/RegistrationProcessView.vue'),
          meta: { layout: layout }
        }

      ]
    }
];