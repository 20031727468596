import { filter } from 'lodash';
export const setUser = (state, user) => {
      
    state.user = user
}
export const setCreatorInformation = (state, user) => {
    state.creatorInfo = user
}

export const setUserList = (state, userList) => {
    state.userList = userList;
}
export const setUserMeta = (state, userMeta) => {
    state.userMeta = userMeta;
}
export const setSelectedUserList = (state, userList) => {
    state.selecteduserList = userList
}

export const setRemoveDeletedItem = (state, userId) => {
    state.userList = filter(state.userList, (item) => item.id !== userId);
}